import axios from "axios";
import { GET_ALL_HOST_LIVE_HISTORY } from "./types";
import { Toast } from "../../util/Toast";

export const getAllHostLiveHistory = (id) => (dispatch) => {
  axios
    .get(`hostLiveHistory/agecyHost?startDate=2025-01-01&endDate=2025-01-31&agencyId=${id}`)
    .then((res) => {
      if (res?.data?.status) {
        dispatch({ type: GET_ALL_HOST_LIVE_HISTORY, payload: res?.data?.data });
      } else {
        Toast("error", res?.data?.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};