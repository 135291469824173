export const formatCoinCount = (currentCoin) => {
    if (currentCoin >= 1_000_000_000) {
      return (currentCoin / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (currentCoin >= 1_000_000) {
      return (currentCoin / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (currentCoin >= 1_000) {
      return (currentCoin / 1_000).toFixed(1).replace(/\.0$/, "") + "K";
    } else {
      return currentCoin.toString();
    }
  };