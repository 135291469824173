// ADMIN HISTORY

export const GET_ADMIN_HISTORY = "GET_ADMIN_HISTORY";

export const GET_ADMIN_EARNING = "GET_ADMIN_EARNING";

// USER HISTORY
export const GET_ADMIN_CASHOUT = "GET_ADMIN_CASHOUT";

export const GET_HOST_HISTORY = "GET_HOST_HISTORY";
export const GET_HOST_LIVE_HISTORY = "GET_HOST_LIVE_HISTORY";

export const GET_HOST_LIVE_DATA = "GET_HOST_LIVE_DATA";

export const GET_AGENCY_LIVE_HISTORY = "GET_AGENCY_LIVE_HISTORY"