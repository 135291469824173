import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getHostLiveData } from "../store/history/history.action";
import dayjs from "dayjs";
import DateRangePicker from "react-bootstrap-daterangepicker";

const convertMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60); // Calculate full hours
  const minutes = totalMinutes % 60; // Remaining minutes

  if (hours > 0 && minutes > 0) {
    return `${hours} hr${hours > 1 ? "s" : ""} ${minutes} min${minutes > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    return `${hours} hr${hours > 1 ? "s" : ""}`;
  } else {
    return `${minutes} min${minutes > 1 ? "s" : ""}`;
  }
};

const HostLiveHistory = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const hostId = location.state.state;
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
  );

  const startAllDate = "1970-01-01";
  const endAllDate = dayjs().format("YYYY-MM-DD");

  const { liveHistoryData } = useSelector((state) => state.history);

  useEffect(() => {
    dispatch(getHostLiveData(hostId, startDate, endDate));
  }, [dispatch,hostId,startDate, endDate]);

  useEffect(() => {
    console.log("liveHistoryData", liveHistoryData);
  }, [liveHistoryData]);

  const handleApply = (event, picker) => {
    const start = dayjs(picker.startDate).format("YYYY-MM-DD");
    const end = dayjs(picker.endDate).format("YYYY-MM-DD");
    setStartDate(start);
    setEndDate(end);

    console.log("startDate", startDate, "endDate", endDate);
    console.log(
      "picker.startDate",
      picker.startDate,
      "picker.endDate",
      picker.endDate
    );
  };

  return (
    <div>
      <div
        className="row mb-2 "
        style={{
          zIndex: "9",

          paddingTop: "15px",
          paddingBottom: "15px",
          paddingLeft: "22px",
          width: "100%",
          top: "0",
          background: "#231C2C",
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <div className="col-4 d-flex align-items-center">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => window.history.back()}
          >
            <path
              d="M1.18529 11.648L7.60196 18.0647C7.77484 18.2317 8.0064 18.3241 8.24674 18.322C8.48709 18.3199 8.717 18.2235 8.88696 18.0535C9.05692 17.8836 9.15332 17.6537 9.15541 17.4133C9.1575 17.173 9.0651 16.9414 8.89812 16.7685L4.04621 11.9166H20.1667C20.4098 11.9166 20.643 11.82 20.8149 11.6481C20.9868 11.4762 21.0834 11.2431 21.0834 11C21.0834 10.7568 20.9868 10.5237 20.8149 10.3518C20.643 10.1799 20.4098 10.0833 20.1667 10.0833H4.04621L8.89812 5.23137C8.98568 5.14681 9.05551 5.04566 9.10355 4.93382C9.15159 4.82198 9.17688 4.7017 9.17794 4.57999C9.179 4.45827 9.1558 4.33757 9.10971 4.22491C9.06362 4.11226 8.99555 4.00991 8.90949 3.92384C8.82342 3.83777 8.72107 3.7697 8.60842 3.72361C8.49576 3.67752 8.37506 3.65433 8.25334 3.65539C8.13163 3.65645 8.01134 3.68173 7.8995 3.72978C7.78767 3.77782 7.68652 3.84765 7.60196 3.9352L1.18529 10.3519C1.01344 10.5238 0.916904 10.7569 0.916904 11C0.916904 11.243 1.01344 11.4761 1.18529 11.648Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="col-4 text-center" style={{ width: "fit-content" }}>
          <p
            className="mb-0 text-white"
            style={{ fontSize: "20px", fontWeight: 500 }}
          >
            Live History
          </p>
        </div>
      </div>
      <div className="container">
        <div className="date-picker d-flex justify-content-end p-2">
          <DateRangePicker
            initialSettings={{
              startDate: undefined,
              endDate: undefined,
              ranges: {
                Today: [dayjs().toDate(), dayjs().toDate()],
                Yesterday: [
                  dayjs().subtract(1, "days").toDate(),
                  dayjs().subtract(1, "days").toDate(),
                ],

                "Last 7 Days": [
                  dayjs().subtract(6, "days").toDate(),
                  dayjs().toDate(),
                ],
                "Last 30 Days": [
                  dayjs().subtract(29, "days").toDate(),
                  dayjs().toDate(),
                ],
                "This Month": [
                  dayjs().startOf("month").toDate(),
                  dayjs().endOf("month").toDate(),
                ],
                "Last Month": [
                  dayjs().subtract(1, "month").startOf("month").toDate(),
                  dayjs().subtract(1, "month").endOf("month").toDate(),
                ],
              },
              maxDate: new Date(),
              singleDatePicker: false,
              linkedCalendars: false,
            }}
            onApply={handleApply}
          >
            <input
              type="text"
              readOnly
              placeholder="Select Date Range"
              // onClick={handleInputClick}
              className={`daterange float-right  mr-4  text-center`}
              value={
                (startDate === startAllDate && endDate === endAllDate) ||
                (startDate === "All" && endDate === "All")
                  ? "Select Date Range"
                  : dayjs(startDate).format("MM/DD/YYYY") &&
                    dayjs(endDate).format("MM/DD/YYYY")
                  ? `${dayjs(startDate).format("MM/DD/YYYY")} - ${dayjs(
                      endDate
                    ).format("MM/DD/YYYY")}`
                  : "Select Date Range"
              }
              style={{
                fontWeight: 500,
                cursor: "pointer",
                background: "#F0F0F0",
                color: "#000",
                display: "flex",
                width: "100%",
                justifyContent: "end",
                fontSize: "13px",
                padding: "10px",
                maxWidth: "226px",
                borderRadius: "64px",
                border: "1px solid transparent",
                height: "48px !important",
                outline: "none",
              }}
            />
          </DateRangePicker>
        </div>
        <div className="container">
          {liveHistoryData.length > 0 ? (
            <>
              {/* Host Details at the Top */}
              <div className="host-details mb-4 mt-2 d-flex justify-content-between">
                <p
                  className="m-0"
                  style={{ fontWeight: "bold", fontSize: "1rem" }}
                >
                  {liveHistoryData[0]?.host?.name}
                </p>
                <p className="text-muted m-0">
                  Unique ID: {liveHistoryData[0]?.host?.uniqueId}
                </p>
              </div>

              {/* Data Table */}
              <table className="table text-center">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Duration</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {liveHistoryData.map((data, index) => (
                    <tr key={index} style={{backgroundColor: "transparent !important"}}>
                      <td>{data.audio ? "Audio" : "Video"}</td>
                      <td
                        style={{
                          color: data.isValid ? "green" : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {convertMinutes(data.liveDuration)}
                      </td>
                      <td>{new Date(data.date).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p style={{ textAlign: "center" }}>No data found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default HostLiveHistory;
