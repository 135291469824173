import { GET_ALL_HOST_LIVE_HISTORY } from "./types";

const initialState = {
  liveHistory: [],
}

export const allHostLiveHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_HOST_LIVE_HISTORY:
      return {
        ...state,
        liveHistory: action.payload,
      };
    default:
      return state;
  }
};